<template>
  <div>
    <div class="cabecera">
      <div id="videoBanners">
        <div class="cont-banner text-center">
          <h2 class="digital">
            {{ $t("hero.title_1") }}
          </h2>
          <h1 style="font-weight: 700;">
            {{ $t("hero.title_main") }}<span class="green"></span>
          </h1>
          <a
            class="btn btn-default my-4"
            @click="$refs.silentBoxBtn.click()"
          >
            <i class="fa fa-play-circle-o" aria-hidden="true"></i>
            {{ $t("hero.know_more") }}
          </a>
          <section id="section04" class="demo">
            <a href="#section02" v-scroll-to="'#element'">
              <span></span>
            </a>
          </section>
          <p></p>
        </div>
      </div>
    </div>
    <silentbox-single
      src="https://www.youtube.com/watch?v=FH055Z9keSI"
      :autoplay="true"
      :hideControls="true"
      description=""
    >
      <div ref="silentBoxBtn"></div>
    </silentbox-single>
    <div id="element" class="contenidoFiltros" ref="porto">
      <div class="container">
        <h2 style="font-weight: 600; letter-spacing: -0.1rem; font-size: 4rem;">
          AICAD<span style="vertical-align: super; font-size: 3rem;">®</span>
          {{ $t("benefits.executive_club") }} <br />
          <span style="font-size: 3rem;">
            <i>{{ $t("benefits.digital_inteligence") }}</i>
          </span>
        </h2>
      </div>
    </div>
    <div class="tematicas">
      <div class="container py-5">
        <div class="row">
          <div class="col-md-6">
            <Contact />
          </div>
          <div class="col-md-6 dcha col-dcha">
            <h2 style="width: 70%;">{{ $t("benefits.what_benefits") }}</h2>
            <ul>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_1") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_2") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_3") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_4") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_5") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_6") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_7") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_8") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_9") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_10") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_11") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_12") }}</a>
              </li>
              <li>
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                <a href="#">{{ $t("benefits.benefit_13") }}</a>
              </li>
            </ul>
            <router-link
              :to="{ path: '/spain/pricing/' }"
              target="_blank"
              class="btn"
            >
              <i
                class="fa fa-sign-in mr-2"
                aria-hidden="true"
                style="vertical-align: middle;"
              ></i>
              {{ $t("benefits.register_me") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div id="element1" class="ranking rank-home py-5">
      <div class="container p-4">
        <p class="text-center">
          <font-awesome-icon
            icon="angle-down"
            class="cyanGreen animateDown"
            id="animateDown"
          />
        </p>
        <h3 class="bg">{{ $t("club.the_club") }}</h3>
        <h2>
          {{ $t("club.increase")
          }}<span>{{ $t("club.yesterday_today_tomorrow") }}</span>
        </h2>
        <div class="row">
          <div class="col-xs-12 col-md-4">
            <h4 class="text-center">{{ $t("club.solidary") }}</h4>
            <p class="text-center">
              {{ $t("club.solidary_text") }}
            </p>
            <p class="text-center rank-nombre">
              {{ $t("club.solidary_subtext") }}
            </p>
          </div>
          <div class="col-xs-12 col-md-4">
            <h4 class="text-center">{{ $t("club.visionary") }}</h4>
            <p class="text-center">
              {{ $t("club.visionary_text") }}
            </p>
            <p class="text-center rank-nombre">
              {{ $t("club.visionary_subtext") }}
            </p>
          </div>
          <div class="col-xs-12 col-md-4">
            <h4 class="text-center">{{ $t("club.leader") }}</h4>
            <p class="text-center">
              {{ $t("club.leader_text") }}
            </p>
            <p class="text-center rank-nombre">
              {{ $t("club.leader_subtext") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="rethink" id="rethink">
      <div class="container pb-5">
        <div class="row">
          <div class="h_title">
            <img 
              loading="lazy"
              src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/ver_todo/logos-banner.png"
              alt="Rethink"
              class="img-responsive"
              style="max-width: 50%;"
            />
            <h2>
              {{ $t("about.title") }}
              <span class="black" style="font-weight: 600;">
                {{ $t("about.title_bold") }}
              </span>
            </h2>
          </div>
        </div>
        <div class="rethink-articulos">
          <div class="row">
            <div class="col-xs-12 text-center">
              <a
                href="#"
                class="btn btn-default cargar-mas-articulos more-hello-articulos"
                >{{ $t("about.see_all") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <section>
      <div class="escuela_negocios mb-5">
        <div class="container">
          <div class="hidden-xs">
          <h2 class="text-center">
            {{ $t("values.title") }}
          </h2>
          <b-row class="justify-content-md-center">
            <div class="text-center">
              <div class="container">
                <b-row class="img_gallery justify-content-md-center">
                  <b-col class="col-40">
                    <a href="#">
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/slider/img1.jpg"
                        class="img-responsive"
                        alt="Video"
                      />
                    </a>
                  </b-col>
                  <b-col class="col-20 my-auto">
                    <a href="#">
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/slider/img3.jpg"
                        class="img-responsive"
                        alt="Video"
                      />
                    </a>
                    <a href="#">
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/slider/img4.jpg"
                        class="img-responsive"
                        alt="Video"
                      />
                    </a>
                  </b-col>
                  <b-col class="col-40">
                    <a href="#" >
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/slider/img2.jpg"
                        class="img-responsive"
                        alt="Video"
                      />
                    </a>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-row>
          </div>
          <h2 class="text-center">{{ $t("solidarity_club.title") }}</h2>
          <div class="">
            <div
              class="col-xs-12 col-sm-12 col-sm-offset-0 col-md-10 col-md-offset-1"
              style="padding: 50px;"
            >
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <ul class="list-inline d-flex flex-wrap service">
                    <li class="cont-txt">
                      <h3>{{ $t("solidarity_club.cards.card_1_title") }}</h3>
                      <font-awesome-icon
                        icon="long-arrow-alt-right"
                        style="font-size: 21px;;position: absolute;left: 0;"
                        class="cyanGreen"
                      />
                      <p>
                        {{ $t("solidarity_club.cards.card_1_text") }}
                      </p>
                    </li>
                    <li class="cont-img my-auto">
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/icons/Mision.png"
                        class="img-responsive"
                        alt="Icono"
                      />
                    </li>
                  </ul>
                </div>
                <div class="col-xs-12 col-sm-6">
                  <ul class="list-inline d-flex flex-wrap service">
                    <li class="cont-txt">
                      <h3>{{ $t("solidarity_club.cards.card_2_title") }}</h3>
                      <font-awesome-icon
                        icon="long-arrow-alt-right"
                        style="font-size: 21px;;position: absolute;left: 0;"
                        class="cyanGreen"
                      />
                      <p>
                        {{ $t("solidarity_club.cards.card_2_text") }}
                      </p>
                    </li>
                    <li class="cont-img my-auto">
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/icons/vision.png"
                        class="img-responsive"
                        alt="Icono"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <ul class="list-inline d-flex flex-wrap service">
                    <li class="cont-txt">
                      <h3>{{ $t("solidarity_club.cards.card_3_title") }}</h3>
                      <font-awesome-icon
                        icon="long-arrow-alt-right"
                        style="font-size: 21px;;position: absolute;left: 0;"
                        class="cyanGreen"
                      />
                      <p>
                        {{ $t("solidarity_club.cards.card_3_text") }}
                      </p>
                    </li>
                    <li class="cont-img my-auto">
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/icons/valore-que-compartimos.png"
                        class="img-responsive"
                        alt="Icono"
                      />
                    </li>
                  </ul>
                </div>
                <div class="col-xs-12 col-sm-6">
                  <ul class="list-inline d-flex flex-wrap service">
                    <li class="cont-txt">
                      <h3>{{ $t("solidarity_club.cards.card_4_title") }}</h3>
                      <font-awesome-icon
                        icon="long-arrow-alt-right"
                        style="font-size: 21px;;position: absolute;left: 0;"
                        class="cyanGreen"
                      />
                      <p>
                        {{ $t("solidarity_club.cards.card_4_text") }}
                      </p>
                    </li>
                    <li class="cont-img my-auto">
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/icons/filosodia-del-club.png"
                        class="img-responsive"
                        alt="Icono"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-6">
                  <ul class="list-inline d-flex flex-wrap service">
                    <li class="cont-txt">
                      <h3>{{ $t("solidarity_club.cards.card_5_title") }}</h3>
                      <font-awesome-icon
                        icon="long-arrow-alt-right"
                        style="font-size: 21px;;position: absolute;left: 0;"
                        class="cyanGreen"
                      />
                      <p>
                        {{ $t("solidarity_club.cards.card_5_text") }}
                      </p>
                    </li>
                    <li class="cont-img my-auto">
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/icons/emprendedores.png"
                        class="img-responsive"
                        alt="Icono"
                      />
                    </li>
                  </ul>
                </div>
                <div class="col-xs-12 col-sm-6">
                  <ul class="list-inline d-flex flex-wrap service">
                    <li class="cont-txt">
                      <h3>{{ $t("solidarity_club.cards.card_6_title") }}</h3>
                      <font-awesome-icon
                        icon="long-arrow-alt-right"
                        style="font-size: 21px;;position: absolute;left: 0;"
                        class="cyanGreen"
                      />
                      <p>
                        {{ $t("solidarity_club.cards.card_6_text") }}
                      </p>
                    </li>
                    <li class="cont-img my-auto">
                      <img
                        loading="lazy" 
                        src="https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/icons/empresas.png"
                        class="img-responsive"
                        alt="Icono"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <AcceptCookies />
  </div>
</template>
<script>
import Contact from "../../components/spain/Contact";
import AcceptCookies from "../../components/spain/AcceptCookies";
export default {
  name: "home",
  components: {
    Contact,
    AcceptCookies
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/css/master-postgrado/bootstrap.min.css";
@import "../../assets/css/master-postgrado/main.min.css";

.cyanGreen {
  color: #0070a9;
}

.img_gallery {
  background: linear-gradient(180deg, #1aedf6 0%, #18485f 100%);
}

.col-40 {
  flex: 0 0 40%;
  max-width: 40% !important;
}

.col-20 {
  flex: 0 0 20%;
  max-width: 20% !important;
}

.demo a {
  align-content: center;
  position: absolute;
  right: 40%;
  left: 40%;
  display: inline-block;
  color: #fff;
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
}
 .demo a:hover {
  opacity: 0.5;
}

#section04 a {
  padding-top: 60px;
}
#section04 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}
.ranking h3.bg {
  margin-left: unset;
  left: 50%;
  transform: translate(-50%);
}

.cont-banner {
  h1 {
    font-size: 10rem;
  }
}

@media screen and (max-width: 767px) {
  .col-md-6.dcha {
    margin: 15px;
  }

  .service {
    .cont-txt {
      width: 100%;
    }

    .cont-img {
      width: 100%;

      img {
        margin: auto;
      }
    }
  }

  .cont-banner {
    h1 {
      font-size: 5rem;
    }

    h2 {
      font-size: 3rem !important;
      line-height: 3rem !important;
    }
  }

  .demo a {
    left: 0;
    width: 100%;
    padding: 0 5px;
  }

  #videoBanners {
    min-height: 100vh;
  }

  .footer {
    .container {
      width: 100% !important;
    }
  }

  .col-dcha {
    padding: 20px !important;
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  #videoBanners {
    min-height: 75vh;
  }

  .demo a {
    left: 0;
    width: 100%;
    padding: 0 5px;
  }
}

.col-dcha {
  padding: 60px;
}

#rethink {
  background: url(https://clubejecutivo.s3.us-east-2.amazonaws.com/assets/images/new_imgs/banner-def.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#rethink .container {
  background: unset;
}

.animateDown {
  -webkit-animation: moveDown 2s infinite;
  animation: moveDown 2s infinite;
}

@keyframes moveDown {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0, 10px);
  }
  40% {
    transform: translate(0, 0);
  }
}

h2.digital {
  width: 33%;
  margin: auto;
}
</style>