<template>
  <div class="wrap-contact3">
    <form
      class="contact3-form validate-form"
      @submit.prevent="onSubmit"
      v-if="!sentSuccess"
    >
      <h2 class="contact3-form-title">{{ $t("contact.contact_us") }}</h2>
      <h3 class="mb-6">{{ $t("contact.more_info") }}</h3>

      <div class="wrap-input3 validate-input" data-validate="Name is required">
        <input
          class="input3"
          type="text"
          name="name"
          :placeholder="'*' + $t('contact.placeholders.name')"
          v-model="form.name"
          required
        />
        <span class="focus-input3"></span>
      </div>

      <div
        class="wrap-input3 validate-input"
        data-validate="Valid email is required: ex@abc.xyz"
      >
        <input
          class="input3"
          type="email"
          name="email"
          :placeholder="'*' + $t('contact.placeholders.email')"
          v-model="form.email"
          required
        />
        <span class="focus-input3"></span>
      </div>

      <div
        class="wrap-input3 validate-input"
        data-validate="Message is required"
      >
        <textarea
          class="input3"
          name="message"
          :placeholder="'*' + $t('contact.placeholders.message')"
          v-model="form.message"
          required
        ></textarea>
        <span class="focus-input3"></span>
      </div>
      <div
        class="wrap-input3 policy"
      >
        <p>{{ $t('convenio.RGPD_youcan') }} <a target="_blank" href="https://aicad.es/page/aviso-legal-privacidad/">{{ $t('convenio.RGPD_policy') }}</a></p>
        <p><strong>{{ $t('convenio.RGPD_responsible_catp') }}</strong>: {{ $t('convenio.RGPD_responsible_answ') }}</p>
        <p><strong>{{ $t('convenio.RGPD_finalidad') }}</strong>: {{ $t('convenio.RGPD_info') }}</p>
        <p><strong>{{ $t('convenio.RGPD_almacenamiento') }}</strong>: {{ $t('convenio.RGPD_almacenamiento_anws') }}</p>
        <p>
          <strong>{{ $t('convenio.RGPD_right') }}</strong>: {{ $t('convenio.RGPD_right_anws') }} <a target="_blank" href="https://aicad.es/page/aviso-legal-privacidad/"
          >{{ $t('convenio.RGPD_limits') }}</a>.
        </p>
      </div>
      <b-button type="submit" class="btn-send mb-4"
        ><i class="fa fa-paper-plane" aria-hidden="true"></i>
        {{ $t("contact.send") }}</b-button
      >
      <h3 v-if="sentFailed" style="color: #ff9ca6;">
        {{ $t("contact.something_wrong") }}
      </h3>
    </form>
    <div v-else-if="sentSuccess" class="send-success">
      <h2>{{ $t("contact.thanks") }}</h2>
      <h3>{{ $t("contact.will_contact") }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: ""
      },
      sentSuccess: false,
      sentFailed: false
    };
  },
  methods: {
    onSubmit() {
      event.preventDefault();
      this.axios
        .post("/api/index.php", this.form)
        .then(response => {
          if (response.status === 200) {
            this.sentSuccess = true;
          }
        })
        .catch(() => {
          this.sentFailed = true;
        });
    }
  }
};
</script>

<style lang="scss">
input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
textarea:-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

.bg-contact3 {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.container-contact3 {
  width: 100%;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.8);
}

.wrap-contact3 {
  height: 875px;
  max-width: 600px;
  background: #80c64a;
  background: linear-gradient(135deg, #0070aa 0%, #017e7c 100%);
  border-radius: 10px;
  overflow: hidden;
  padding: 60px;
}

.contact3-form {
  width: 100%;
  position: relative;
  top: 50%;
  transform: translate(0%, -50%);
}

.contact3-form-title {
  color: #ffffff;
  font-size: 36px;
  font-family: "KelsonSans-Bold", sans-serif;
  font-weight: 600;
}

.wrap-input3 {
  width: 100%;
  position: relative;
  border-bottom: 2px solid rgba(255, 255, 255, 0.24);
  margin-bottom: 27px;
}

.input3 {
  display: block;
  width: 100%;
  background: transparent;
  font-size: 15px;
  color: #fff;
  line-height: 1.2;
  padding: 0 5px;
}

.focus-input3 {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.focus-input3::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;

  background: #fff;
}

input.input3 {
  height: 45px;
}

textarea.input3 {
  min-height: 115px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.input3:focus + .focus-input3::before {
  width: 100%;
}

.has-val.input3 + .focus-input3::before {
  width: 100%;
}

.contact3-form-btn {
  color: #ffffff;
  font-size: 18px;
  font-family: "EncodeSansSemiExpanded-Medium", sans-serif;
  margin-left: 20px;
  border-radius: 50px;
  margin-top: 20px;
}

.contact3-form-btn:focus,
.contact3-form-btn:focus {
  color: #ffffff;
}

/*------------------------------------------------------------------
  [ Alert validate ]*/

.alert-validate::after {
  content: "\f12a";
  display: block;
  position: absolute;
  color: #c80000;
  font-size: 16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 8px;
}

.alert-validate:hover:before {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 992px) {
  .alert-validate::before {
    visibility: visible;
    opacity: 1;
  }
}

.input3-select {
  display: none;
}

.send-success {
  width: 90%;
  color: white;
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);

  h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .wrap-contact3 {
    padding: 40px 20px;
    height: auto;

    .contact3-form {
      position: unset;
      transform: none;
      top: 0;
    }
  }
}

@media screen and (max-width: 1499px) {
  .rank-home {
    h3 {
      font-size: 10rem !important;
    }
  }
}

.btn-send {
  background: unset !important;
  border: unset !important;
  font-size: 1.5rem !important;
  transition: 0.3s ease !important;
}

.btn-send:hover {
  transform: scale(1.1);
}

.mb-6 {
  margin-bottom: 6rem;
  color: white;
}
.policy p{
  color: rgb(253, 253, 253);
  font-size: 10px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0rem;
}
.policy a{
  color: rgb(0, 217, 255);
  cursor:pointer;
}
.policy a:hover{
  color: rgb(0, 162, 255);
  cursor:pointer;
}
</style>
